<template>
  <Pic :src="avatar" fallback="/img/user/Avatar@2x.png" />
</template>

<script lang="ts">
import Pic from 'common/Pic.vue'
export default {
  name: 'TheAvatar',
  components: { Pic },
  props: {
    avatar: {
      type: String,
    },
  },
}
</script>
