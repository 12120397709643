
import { useBindGoogle } from '@/features/googleLogin/googleLogin.fns'
import { defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'

export default defineComponent(
  {
    name: 'TheSettingGoogle',
    components: { Icon },
    setup () {
      const { googleEmail, hasBind, bind } = useBindGoogle()

      return {
        googleEmail,
        hasBind,
        bind,
      }
    },
  },
)
