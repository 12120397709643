<template>
  <PageWithHeader>
    <template #title><t path="settings_2">Settings</t></template>
    <div class="setting pt-24 f-md px-16">
      <div class="menu df-middle df-between">
        <t path="settings_12">Color scheme</t>
        <div class="df-middle">
          <TheCheckItem class="mr-24" :active="mode==='light'" @click="changeTheme('light')">
            <t path="settings_13">Bright</t>
          </TheCheckItem>
          <TheCheckItem class="mr-24" :active="mode==='dark'" @click="changeTheme('dark')">
            <t path="settings_14">Dark</t>
          </TheCheckItem>
          <!-- <TheCheckItem :active="mode==='system'" @click="changeTheme('system')">
            <t path="">System</t>
          </TheCheckItem> -->
        </div>
      </div>
      <div class="menu df-middle" @click="updateAvatar">
        <t path="settings_3" class="flex-1">Avatar</t>
        <div>
          <TheAvatar :avatar="avatar || ''" class="avatar" />
        </div>
        <Icon name="caret-right" class="f-nm" />
      </div>
      <Line />
      <router-link to="/me/setting/nick" class="menu df-middle">
        <t path="settings_4" class="flex-1">NickName</t>
        <span class="f-nm f-medium">{{ nickName }}</span>
        <Icon name="caret-right" class="f-nm" />
      </router-link>
      <Line />
      <div class="menu df-middle">
        <t path="settings_5" class="flex-1">Account</t>
        <span class="f-nm c-tip f-medium">{{ account }}</span>
      </div>
      <Line />
      <div class="menu df-middle">
        <t path="settings_10" class="flex-1">Mobile Number</t>
        <span class="f-nm c-tip f-medium">{{ mobile }}</span>
      </div>
      <Line />
      <router-link to="/reset" class="menu df-middle">
        <t path="settings_6" class="flex-1">Set Password</t>
        <Icon name="caret-right" class="f-nm" />
      </router-link>
      <Line />
<!--      <TheSettingItem />-->
      <TheSettingGoogleVue />
      <Line />
      <div class="menu df-middle" v-if="islamFlag">
        <t path="settings_11" class="c-tip">Islamic Account  (Swap Free)</t>
      </div>
      <Line v-if="islamFlag" />
    </div>
    <div class="controls">
      <Line />
      <Button class="signOut" @click="logOut">
        <t path="settings_9" #="{td}" custom>{{ td || 'Sign out' }}</t>
      </Button>
    </div>
  </PageWithHeader>
</template>
<script lang="ts">
import { syncLogout } from '@/modules/user/user'
import { localGet } from 'essential/store/localStore'
import { keymap } from '@/config'
import { useUpdateAvatar } from '@/pages/setting/setting'
import TheAvatar from '@/pages/setting/components/TheAvatar.vue'
import { defineComponent, shallowRef } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Icon from '@/components/Icon.vue'
import Button from '@/components/Button.vue'
import Line from '@/components/Line.vue'
import TheSettingItem from '@/features/fbLogin/TheSettingItem.vue'
import TheCheckItem from '@/pages/setting/components/TheCheckItem.vue'
import { themeInit } from '@/modules/app/app'
import TheSettingGoogleVue from '@/features/googleLogin/TheSettingGoogle.vue'

export default defineComponent({
  name: 'Setting',
  components: { Line, Button, Icon, PageWithHeader, TheAvatar, TheCheckItem, TheSettingGoogleVue },
  setup () {
    const [avatar, updateAvatar] = useUpdateAvatar(localGet(keymap.user.avatar))
    const defaultMode = localGet(keymap.label.theme)
    const mode = shallowRef(defaultMode || 'light')

    const changeTheme = (m: string) => {
      mode.value = m
      themeInit(m)
    }

    return {
      nickName: localGet(keymap.user.nickName),
      account: localGet(keymap.user.accountNum),
      mobile: localGet(keymap.user.mobile),
      islamFlag: Boolean(Number(localGet(keymap.user.islamFlag))) || false,
      updateAvatar,
      logOut: syncLogout,
      avatar,
      mode,
      changeTheme,
    }
  },
})
</script>

<style scoped lang="scss">
.signOut {
  width: 100%;
  background: transparent;
}

.controls {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  .line::after {
    border-color: var(--color-tip);
  }
}

.menu {
  height: 48px;
}

.setting .line::after {
  border-color: var(--color-grey);
}

.avatar {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
}

</style>
