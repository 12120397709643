
import { syncLogout } from '@/modules/user/user'
import { localGet } from 'essential/store/localStore'
import { keymap } from '@/config'
import { useUpdateAvatar } from '@/pages/setting/setting'
import TheAvatar from '@/pages/setting/components/TheAvatar.vue'
import { defineComponent, shallowRef } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Icon from '@/components/Icon.vue'
import Button from '@/components/Button.vue'
import Line from '@/components/Line.vue'
import TheSettingItem from '@/features/fbLogin/TheSettingItem.vue'
import TheCheckItem from '@/pages/setting/components/TheCheckItem.vue'
import { themeInit } from '@/modules/app/app'
import TheSettingGoogleVue from '@/features/googleLogin/TheSettingGoogle.vue'

export default defineComponent({
  name: 'Setting',
  components: { Line, Button, Icon, PageWithHeader, TheAvatar, TheCheckItem, TheSettingGoogleVue },
  setup () {
    const [avatar, updateAvatar] = useUpdateAvatar(localGet(keymap.user.avatar))
    const defaultMode = localGet(keymap.label.theme)
    const mode = shallowRef(defaultMode || 'light')

    const changeTheme = (m: string) => {
      mode.value = m
      themeInit(m)
    }

    return {
      nickName: localGet(keymap.user.nickName),
      account: localGet(keymap.user.accountNum),
      mobile: localGet(keymap.user.mobile),
      islamFlag: Boolean(Number(localGet(keymap.user.islamFlag))) || false,
      updateAvatar,
      logOut: syncLogout,
      avatar,
      mode,
      changeTheme,
    }
  },
})
