<template>
  <div :class="{'disable': hasBind}" class="df-middle" style="height: 48px;" @click="bind">
    <t as="p" path="settings_16" class="flex-1" v-if="hasBind">Connected to the Google Account</t>
    <t as="p" path="settings_15" class="flex-1" v-else>Connect to the Google Account</t>
    <div class="t-right ml-4" :class="{'fb-name': hasBind}">
      <span v-if="hasBind">{{googleEmail}}</span>
      <Icon v-else name="caret-right" class="f-nm" />
    </div>
  </div>
</template>

<script lang="ts">
import { useBindGoogle } from '@/features/googleLogin/googleLogin.fns'
import { defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'

export default defineComponent(
  {
    name: 'TheSettingGoogle',
    components: { Icon },
    setup () {
      const { googleEmail, hasBind, bind } = useBindGoogle()

      return {
        googleEmail,
        hasBind,
        bind,
      }
    },
  },
)
</script>
<style scoped lang="scss">
  .disable {
    pointer-events: none;
    color: var(--color-tip);
  }

  .fb-name {
    max-width: 40%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
